import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—April 2010" />
	<Old2005BlogPage>
		<h2>April 2010</h2>



		<h3 className="blogdate">
		Sunday, April 04, 2010
		</h3>






		<h4 className="blogitemtitle">
		Last post before Blogger cuts off FTP
		</h4>





		   <div className="blogitembody">
		   Well, it's not like I've been blogging tonnes lately, anyhow. But Google/Blogger have decided to drop FTP support for the Blogger platform, which means that folks like myself can no longer use Blogger to populate a blog on their own website.<br /><br />In light of that, and in the hope that starting a new blog in the future might get me excited about blogging again, I am going to seal off this old blog soon.  That means it will still be around for historical purposes, but new comments will no longer be feasible, and neither will new posts.<br /><br />For those of you who are subscribed to this blog using my feedburner feed (the one advertised from the subscription area of my website), you are free to stay subscribed as I'll continue using this feed for my new blog when it is born.<br /><br />If you have no idea what I'm talking about (feeds, blogs), then just have a laugh and find the next funny thing on the Internet.
		   </div>
		   <div className="blogitemfooter">
		   posted by Jesse @ 1:40 PM &nbsp;
		   </div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
